<template>
	<div>
		<div class="card p-2">
			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<b-th v-for="(title, key) in headTable" :key="key">
								<div
									class="d-flex align-items-center justify-content-center cursor-pointer"
									v-if="title.visible"
								>
									<p class="mb-0 mr-1">{{ title.title }}</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in inventories" :key="index">
							<template v-if="inventories.length > 0">
								<b-td class="">
									<p class="mb-0 font-weight-bolder">{{ item.code }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0" v-if="item.start_date">{{ item.start_date | myGlobalDayShort }}</p>
									<p class="mb-0" v-else>Sin fecha</p>
								</b-td>

								<b-td class="text-center">
									<p
										:style="{}"
										class="mb-0 font-weight-bolder"
										@click="item.status_id != 3 && addPlates(item)"
										:class="[
											item.status_id != 3 ? 'text-primary cursor-pointer' : 'text-secondary',
										]"
									>
										{{ item.value.length }}
									</p>
								</b-td>

								<b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[
											item.status_id != 3 ? 'text-success cursor-pointer' : 'text-secondary',
										]"
										@click="item.status_id != 3 && item.match != 0 && setActionInventory(item, 0)"
									>
										{{ item.counters.match }}
									</p>
								</b-td>

								<!-- <b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[
											item.status_id == 1 && item.counters.un_match != 0 ? 'text-warning cursor-pointer' : 'text-secondary',
										]"
										@click="
											(item.status_id == 1 && item.counters.un_match != 0) && setActionInventory(item, 1)
										"
									>
										{{ item.counters.un_match }}
									</p>
								</b-td> -->

								<b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[item.status_id == 1 && item.counters.spare != 0 ? 'text-danger cursor-pointer' : 'text-secondary']"
										@click="(item.status_id == 1 && item.counters.spare != 0) && setActionInventory(item, 1)"
									>
										{{ item.counters.spare }}
									</p>
								</b-td>

								<b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[item.status_id == 1 && item.counters.missing != 0 ? 'text-danger cursor-pointer' : 'text-secondary']"
										@click="(item.status_id == 1 && item.counters.missing != 0) && setActionInventory(item, 2)"
									>
										{{ item.counters.missing }}
									</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.name }}</p>
									<span class="font-small-3">{{ item.created_at | myGlobalDayShort }}</span>
								</b-td>

								<b-td class="">
									<b-button
										@click="finishedInventory(item)"
										variant="success"
										v-b-tooltip.hover
										size="sm"
										title="Finalizar"
										:disabled="!(item.counters.un_match == 0 && item.counters.missing == 0 && item.counters.spare == 0)"
										v-if="item.status_id != 3"
									>
										<FeatherIcon icon="CheckCircleIcon" size="16" class="icon text-white" />
									</b-button>
									<FeatherIcon
										v-else
										v-b-tooltip.hover
										title="Finalizado"
										icon="CheckIcon"
										size="20"
										class="icon text-primary"
									/>
								</b-td>
							</template>
						</b-tr>
						<b-tr v-if="inventories.length == 0">
							<b-td colspan="10" class="text-center">
								<p class="mb-0 font-weight-bold">Sin inventarios encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>

		<b-sidebar
			v-model="sidebarCrearInventario.show"
			:title="sidebarCrearInventario.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-2 bg-white"
			scrollable
			@hidden="hideModal"
		>
			<CreateInventorySpecimens
				@refresh="refresh"
				v-if="sidebarCrearInventario.show"
				:data="dataSpecimens"
				:module="module"
			/>
		</b-sidebar>
	</div>
</template>

<script>
import inventoryService from "@/services/inventory.service";
import { mapState, mapActions, mapMutations } from "vuex";
import CreateInventorySpecimens from "../components/CreateInventorySpecimens.vue";
import fakeSpecimens from "../fake_specimens.js";

export default {
	name: "ViewInventorySpecimensChild",
	components: {
		CreateInventorySpecimens,
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },
				{ key: "fecha", title: "Fecha", visible: true },
				{ key: "plates", title: "Placas ingresadas", visible: true },
				{ key: "match", title: "#Coinciden", visible: true },
				// { key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "leftOver", title: "#sobran", visible: true },
				{ key: "missing", title: "#Faltan", visible: true },
				{ key: "usuario", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			sidebarCrearInventario: {
				show: false,
				title: null,
				data: [],
			},
			dataSpecimens: [],
		};
	},
	computed: {
		...mapState("inventorySpecimens", ["inventories", "inventory","countersMatch"]),
		module() {
			return this.$route.meta.module;
		},
	},
	async mounted() {
		// this.isPreloading();
		// this.SELECT_INVENTORY({
		// 	item: {
		// 		value: [],
		// 		start_date: null,
		// 	},
		// });
		// this.dataSpecimens = fakeSpecimens;
		await this.init();
		// console.log('module', this.$route.meta.module);
	},
	methods: {
		...mapActions("inventorySpecimens", ["getInventories"]),
		...mapMutations("inventorySpecimens", ["SELECT_INVENTORY", "UPDATE_INDEX_INVENTORY"]),
		async init() {
			this.isPreloading();
			await this.getInventories({ module: this.module });
			this.dataSpecimens = this.countersMatch;
			// console.log('df', this.dataSpecimens, this.countersMatch);
			
			// this.isPreloading(false);
		},
		createInventory() {
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Nuevo inventario - ${this.module == 1 ? "Ejemplares" : "Pollos"}`;
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
		setActionInventory(item, key) {
			const name = this.module == 1 ? `inventory-specimens-by-id-sr` : `inventory-chicks-by-id-sr`;
			this.SELECT_INVENTORY({ item });

			this.UPDATE_INDEX_INVENTORY({ index: key });

			this.$router.push({
				name,
				params: {
					inventory_id: item.id,
				},
				query: {
					match: key,
				},
			});
		},
		addPlates(item) {
			this.SELECT_INVENTORY({ item });
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Inventario ${this.inventory.code}`;
		},
		async processInventory(inv) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await inventoryService.update({
				id: inv.id,
				status_id: 2,
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Corrales", "SuccessIcon", message);
			await this.init();
		},
		async finishedInventory(inv) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await inventoryService.update({
				id: inv.id,
				status_id: 3,
				module: this.module
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Corrales", "SuccessIcon", message);
			await this.init();
		},
		async refresh() {
			this.sidebarCrearInventario.show = false;
			await this.init();
		},
		hideModal() {
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
	},
};
</script>
<style scoped lang="scss"></style>
